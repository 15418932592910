import { AuthGuard } from 'src/app/core/guard/auth.guard';
import { MenuItem } from './menu.model';
export const MENU: MenuItem[] = [

  {
    label: 'Dashboard',
    icon: 'home',
    link: '/base/dashboard'
  },
  {
    label: 'profile',
    icon: 'user',
    link: '/base/patient/profile'
  },
  {
    label: 'Patient History',
    icon: 'clock',
    link: '/base/patient/patient-history'
  },
  {
    label: 'Previous Record',
    icon: 'book',
    link: '/base/patient/previous-record'
  },
  {
    label: 'Appoinment',
    icon: 'clipboard',
    link: '/base/patient/appoinment'
  },
  {
    label: 'Consultations',
    icon: 'video',
    link: '/base/patient/consultation'
  },
  {
    label: 'Medicine Orders',
    icon: 'package',
    link: '/base/patient/Medicine-orders'
  },
  {
    label: 'Lab Test',
    icon: 'thermometer',
    link: '/base/patient/Lab-Test'
  },
  {
    label: 'Payments',
    icon: 'dollar-sign',
    link: '/base/patient/payments'
  },
  {
    label: 'Articals',
    icon: 'book-open',
    link: '/base/patient/articles'
  },
  {
    label: 'Feedback',
    icon: 'star',
    link: '/base/patient/feedback'
  },
  {
    label: 'Helpdesk',
    icon: 'help-circle',
    link: '/base/patient/helpdesk'
  }


  // {
  //   label: "Admin",
  //   icon: "book",
  //   subItems: [
  //     {
  //       label: "Users",
  //       link: "/base/admin/users",
  //     },
  //     {
  //       label: "Roles",
  //       link: "/base/admin/roles",
  //     },
  //     {
  //       label: "Authorize",
  //       link: "/base/admin/authorize",
  //     },
  //     {
  //       label: "Change Password",
  //       link: "/base/admin/changepassword",
  //     },
  //   ],
  // },
];