import { Component, OnInit, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { UserService } from '../../pages/auth/UserDetails/user.service';
import { GlobalStorage } from '../../../core/Gloabl/Global';
import { Notification} from '../../../core/Notifications/Notification';
import { environment } from 'src/environments/environment';
import { ServiceService } from 'src/app/module/patient/service.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  username: string;
  userdata: any;
  // useremail: string;

  Image_Url;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private user: UserService,
    private store: GlobalStorage,
    private Notifi:Notification,
    private service:ServiceService
  ) { }

  ngOnInit(): void {
    this.service.Get_Userprofile()
    .subscribe((data)=>{
      console.log(data)
      this.userdata = data;
      console.log(this.userdata.userName)
      console.log(this.userdata)
      // this.firstName
      // this.Image_Url= { url: `${environment.API_Base_URL}` +`User/GetProfile_Images?filename=${data[0].image}`};
      if(this.Image_Url == ''){
        this.Image_Url={url:'/assets/images/profile.png'}
      }
      if(this.username ==''){
        this.username=''
      }
    })
    //this.Notifi.successmsg(this.username +" logged Successfully.");
  }

  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e) {
    e.preventDefault();
    this.user.logout();
    this.router.navigate(['/auth/login']);
  }

}
