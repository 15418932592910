import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './core/guard/auth.guard';
import { AppComponent } from './app.component';
import { LayoutModule } from './views/layout/layout.module';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { HttpClientModule } from '@angular/common/http';
import { FormGroup, FormsModule, ReactiveFormsModule,FormBuilder} from '@angular/forms';
import { httpInterceptorProviders } from './core/Interceptor';
import { UserService } from '../app/views/pages/auth/UserDetails/user.service';
import { UserProfile } from '../app/views/pages/auth/UserDetails/user.profile';
import { DataTablesModule } from 'angular-datatables';
import { sharedModule } from '../app/shared/share.module';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxUiLoaderRouterModule, NgxUiLoaderConfig, POSITION, SPINNER, PB_DIRECTION } from 'ngx-ui-loader';

import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import { NgWizardModule, NgWizardConfig, THEME } from 'ng-wizard';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { PatientComponent } from './module/patient/patient.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import {MatInputModule} from '@angular/material/input';
import { BaseComponent } from './views/layout/base/base.component';
import { AddAppoinmentsComponent } from './module/patient/add-appoinments/add-appoinments.component';
import { MatDatepickerModule } from '@angular/material/datepicker';

// import {
//   NgxMatDatetimePickerModule,
//   NgxMatNativeDateModule,
//   NgxMatTimepickerModule
// } from '@angular-material-components/datetime-picker';


export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};
const Config: NgxUiLoaderConfig = {
  bgsColor: "#09ecfb",
  bgsOpacity: 0.5,
  bgsPosition: "bottom-right",
  bgsSize: 60,
  bgsType: "ball-spin-clockwise",
  blur: 5,
  delay: 0,
  fgsColor: "#09ecfb",
  fgsPosition: "center-center",
  fgsSize: 60,
  fgsType: "ball-spin-clockwise",
  gap: 24,
  logoPosition: "center-center",
  logoSize: 120,
  masterLoaderId: "master",
  overlayBorderRadius: "0",
  overlayColor: "rgba(40, 40, 40, 0.8)",
  pbColor: "RED",
  pbDirection: "ltr",
  pbThickness: 5,
  hasProgressBar: true,
  textColor: "#FFFFFF",
  textPosition: "center-center",
  maxTime: -1,
  minTime: 300
};

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default
};

@NgModule({
  declarations: [
    AppComponent,
    PatientComponent,

  ],
  imports: [
    NgxUiLoaderModule.forRoot(Config),
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
    NgxUiLoaderRouterModule.forRoot({ showForeground: true }),
    LayoutModule,
    FormsModule, ReactiveFormsModule,FormsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DataTablesModule,
    sharedModule,
    MatFormFieldModule,
    MatRadioModule,
    MatInputModule,
    NgWizardModule.forRoot(ngWizardConfig),
    SweetAlert2Module.forRoot(),
    MatDatepickerModule
 
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },{provide: LocationStrategy, useClass: HashLocationStrategy},
    httpInterceptorProviders,
    UserService,
    UserProfile,
    AuthGuard,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
        }
      }
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
