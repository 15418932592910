import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private http:HttpClient) { }
  Get_Userprofile(){
    return this.http.get(`${environment.API_Base_URL}`+`User/GetUserByname`)
  }
  update_profile(data){
    return this.http.put(`${environment.API_Base_URL}`+`User/UpdateUserProfile`,data)
  }
  Get_Appointment_byId(){
    return this.http.get(`${environment.API_Base_URL}`+`Appointment/Self/GetAppointmentById`)
  }

  Get_consultant_byId(){
    return this.http.get(`${environment.API_Base_URL}`+`Consultation/Self/GetConsultationById`)
  }

  Delete_appoin(data){
    return this.http.delete(`${environment.API_Base_URL}`+`Appointment/Admin/DeleteAppointment?appt_Id=${data}`)
  }

  Insert_doc(data){
    return this.http.post(`${environment.API_Base_URL}`+`PatientDocument/InsertPatientDocument`, data )
  }

  Get_document(){
    return this.http.get(`${environment.API_Base_URL}`+`PatientDocument/GetAllPatientDocument`)
  }
  
}
